import React from "react"

import SimpleLayout from "../Layout/SimpleLayout"
import Pontuation from "../Pontuation/Pontuation"
import Accordeon from "../Accordeon/Accordeon"
import SEO from "../seo"

import styles from "./styles/faq.module.css"

const FAQs = ({ page, headerImg, accordeonImg, ...props }) => {
  return (
    <SimpleLayout path={props.path}>
      <SEO
        title="LeapLane | FAQs"
        description="Find out more about software development, web apps and Android and iOS mobile applications."
      />
      <header className={styles.header}>
        <div className={styles.container}>
          <h1 className={styles.sectionTitle}>
            {page.title}
            <Pontuation>.</Pontuation>
          </h1>
          <h2 className={styles.sectionDescription}>{page.subtitle}</h2>
        </div>
        <img src={headerImg.fixed.src} className={styles.headerImg} alt="" />
      </header>
      <section className={styles.faqs}>
        <div className={styles.container}>
          {page.faqs.map(faq => (
            <Accordeon
              key={faq.title}
              title={faq.title}
              description={faq.description}
              image={accordeonImg}
            />
          ))}
        </div>
      </section>
    </SimpleLayout>
  )
}

export default FAQs
